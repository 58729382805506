import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input, EventEmitter, Output  } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

	@Input() laDevise : any ;

	allPartners : any ;

	datedeb : any ;
	datefin : any ;

	userchoice = 1 ;

	allServiceSituation: any ;

    totalIn = 0 ; 
    totalOut =  0 ; 
    totalVersement =  0 ;

	samacaabiyoonwii = "" ;

	dataSourceSituations : MatTableDataSource<any> ;

	displayedColumns: string[] = ['Date', 'Operation', 'Monnaie', 'Taux Change', 'Montant Devise', 'Coutprix'];
	dataSource  : any ;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	allTransactions : any ;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 
	}

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
		this.updateHistoGlobal() ;
	}


	updateHistoGlobal(){

	    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
	    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

	    var nomdevise = this.laDevise.nom ;

	    this.configServer.getAllTransactionsGlobalByDevise(this.samacaabiyoonwii, nomdevise, debut, fin).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.allTransactions = this.retourClientWS.data ; 

	        console.log(this.allTransactions) ;
		    this.dataSource = new MatTableDataSource(this.allTransactions);
	      }
	      else
	        console.log("Failed") ;
	    }) ;	    


	}


	getDeviseSituationsPeriode(){

		var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
		var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');
		
		this.configServer.getDeviseSituationsPeriode(this.samacaabiyoonwii, debut, fin ).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;
		  console.log(this.retourClientWS) ;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allServiceSituation = this.retourClientWS.data ; 
		    //this.computeBankTotals() ;

	        this.dataSourceSituations =  new MatTableDataSource(this.getBankOps()) ;
	        this.dataSourceSituations.paginator = this.paginator;
		    this.dataSourceSituations.sort = this.sort;

		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	computeBankTotals(){
		console.log("Tjerer .... 147") ;
	    this.totalIn = 0 ; 
	    this.totalOut =  0 ; 
	    this.totalVersement =  0 ;
		for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
			if(this.laDevise.idcurrency == this.allServiceSituation[j].idBankPart){
				this.totalIn += Number(this.allServiceSituation[j].totalEnvoie) ;
				this.totalOut += Number(this.allServiceSituation[j].totalRetrait) ;
				this.totalVersement += Number(this.allServiceSituation[j].versement) ;		
			}
		}
	}


	getBankOps(){
		console.log("Tjerer .... 147") ;
	    let allBankSituations = [] ; 
	    
		for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
			if(this.laDevise.idBank == this.allServiceSituation[j].idBankPart){
				allBankSituations.push(this.allServiceSituation[j]) ;
			}
		}

		return allBankSituations ;
	}




	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


	getMontantFormatee(montant){
		let resultd = Number(montant).toLocaleString('fr-FR');
		return resultd ;
	}



}
