import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';




@Component({
  selector: 'app-makenewrate',
  templateUrl: './makenewrate.component.html',
  styleUrls: ['./makenewrate.component.css']
})

export class MakenewrateComponent implements OnInit {

  prenomUser = "" ;
  nomUser = "" ;
  telephoneUser = "" ;
  permisUser = "" ;
  loginUser = "" ;

  regionPoint = "" ;

  idpoint = "" ;


  adressepoint = "" ;

  samacaabiyoonwii = "" ; 
 
  nomBank = "" ;
  sigle = "---" ;

  choosenPoint : any ; 

  allCompagnies : any ;

  allRegions = ["Dakar", "Diourbel", "Fatick", "Kaolack", "Kolda", "Louga", "Matam", 
                    "St Louis", "Tambacounda", "Thiès", "Ziguinchor", "Kaffrine", "Kédougou", "Sédhiou"] ;

  useral : any ;

  doneCreating = 0 ;

  nomdevise : any ;
  codedevise : any ;

  retourClientWS  : ReponseWebService ;

  montant : any ;

  voletTampon : any ; volet : any ; successOp : any ; failureOp : any ;



  isIconed = 0 ;    
  choosenIcon = "" ;    
  choosenCode = "" ;    
  choosenNom = "" ;    
  choosenId = 0 ;    

  currencies : any ;

  userinfos : any ;

  choosenOp : any ;


  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<MakenewrateComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) {
  	this.useral = data.opt ;
    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
  }


  ngOnInit(): void {
      this.getCurrencies() ;
  }


  onCurrencyChange(event){
    let choosenIndex = event.value ;    
    if(choosenIndex!=-1){
      this.choosenCode = this.currencies[choosenIndex].code ;    
      this.choosenNom = this.currencies[choosenIndex].nom ;    
      this.choosenId = this.currencies[choosenIndex].id ;       
    }else{
      this.choosenCode = "XOF" ;    
      this.choosenNom = "Francs CFA" ;    
    }
  }


  onOpChange(event){
    let choosenValue = event.value ;    
    this.choosenOp = choosenValue ;

  }




  getCurrencies(){

      this.configServer.getCurrencies(this.samacaabiyoonwii).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutrec = this.retourClientWS.statutreq ;

        if(statutrec==1){
          this.currencies = this.retourClientWS.data ; 
        }
        else
          console.log("Failed") ;
      }) ;    
  }

  saveOperation(){

    this.voletTampon =this.volet ;
    this.volet = "loader" ;

    this.configServer.updateChangeRate(this.samacaabiyoonwii, this.montant, this.choosenOp, this.choosenNom, this.choosenCode, this.choosenId).then( (retourbi) => {

      this.retourClientWS = retourbi ;
      
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Operation Saved !") ;
        this.successOp = true ;
        this.montant = undefined ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }



  creerBank(): void {
    if( this.nomBank.length>=2 ){

      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

      this.configServer.createBank(this.samacaabiyoonwii, this.nomBank ).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq ;
        if(statutreq==1){ 
          console.log("Done. Bank created !") ;
          this.doneCreating = 1 ;
        }
        else
          console.log("Failed") ;
      }) ;          
    }    
  }


  creerDevise(): void {

    if( this.nomdevise.length>=2 && this.codedevise.length>=2 ){

      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

      this.configServer.createCurrency(this.samacaabiyoonwii, this.nomdevise, this.codedevise ).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq ;
        if(statutreq==1){ 
          console.log("Done. Bank created !") ;
          this.doneCreating = 1 ;
        }
        else
          console.log("Failed") ;
      }) ;          
    }    
  }


  choosedInSelect(choosenCompanieName){
    this.nomBank = choosenCompanieName ;
  }

  choosedInSelectRegion(regionNameEvent){
    this.regionPoint = regionNameEvent.value;
  }


  choosedInSelectPoint(pointIndexEvent){
    this.choosenPoint = this.allCompagnies[pointIndexEvent.value]  ;
  }


  creerAdminAndCompany(): void {

    if(this.nomBank.length>=2 && this.regionPoint.length>=2 &&  this.adressepoint.length>=2 ){
      this.configServer.createCompanyAndAdmin(1, this.nomBank, this.regionPoint, this.adressepoint).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutrec = this.retourClientWS.statutreq ;
        if(statutrec==1){
          console.log("Done") ;
          this.doneCreating = 1 ;
        }
        else
          console.log("Failed") ;
      }) ;          
    }
  }


  getAllCompanies(){

    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

    this.configServer.getAllCompanies(this.samacaabiyoonwii ).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        this.allCompagnies = this.retourClientWS.data ; 
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


  annuler(){
  	this.dialogRef.close() ;
  }


}
