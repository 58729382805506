import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';




@Component({
  selector: 'app-make-new-rate',
  templateUrl: './make-new-rate.component.html',
  styleUrls: ['./make-new-rate.component.css']
})



export class MakeNewRateComponent implements OnInit {

  prenomUser = "" ;
  nomUser = "" ;
  telephoneUser = "" ;
  permisUser = "" ;
  loginUser = "" ;

  regionPoint = "" ;

  idpoint = "" ;


  adressepoint = "" ;

  samacaabiyoonwii = "" ; 
 
  nomBank = "" ;
  sigle = "---" ;

  choosenPoint : any ; 

  allCompagnies : any ;

  allRegions = ["Dakar", "Diourbel", "Fatick", "Kaolack", "Kolda", "Louga", "Matam", 
                    "St Louis", "Tambacounda", "Thiès", "Ziguinchor", "Kaffrine", "Kédougou", "Sédhiou"] ;

  useral : any ;

  doneCreating = 0 ;

  nomdevise : any ;
  codedevise : any ;

  retourClientWS  : ReponseWebService ;


  constructor(){
  }


  ngOnInit(): void {
  }


}
