import { Component, OnInit, Inject  } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';

@Component({
  selector: 'app-useroptions',
  templateUrl: './useroptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class UseroptionsComponent implements OnInit {

  userinfos : any ;

  samacaabiyoonwii = "" ;

  retourClientWS  : ReponseWebService ;

  volet = "main" ;
  voletTampon = "" ;

  successOp = false ;

  failureOp = false ;


  activite : any ;
  dateLastConnexion : any ;
  actifDepuis : any ;
  inactifDepuis : any ;


  montant : any ;


  isIconed = 0 ;    
  choosenIcon = "" ;    
  choosenCode = "" ;    
  choosenNom = "" ;    
  choosenId = 0 ;    

  currencies : any ;


  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<UseroptionsComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) { 
    this.userinfos = data.opt ;
    console.log(this.userinfos) ;
  }

  ngOnInit(): void {
      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
      this.getLastActivity() ;
      this.getCurrencies() ;
  }


  onCurrencyChange(event){
    let choosenIndex = event.value ;    
    if(choosenIndex!=-1){
      this.choosenCode = this.currencies[choosenIndex].code ;    
      this.choosenNom = this.currencies[choosenIndex].nom ;    
      this.choosenId = this.currencies[choosenIndex].id ;       
    }else{
      this.choosenCode = "XOF" ;    
      this.choosenNom = "Francs CFA" ;    
    }

  }

  getCurrencies(){

      this.configServer.getCurrencies(this.samacaabiyoonwii).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutrec = this.retourClientWS.statutreq ;

        if(statutrec==1){
          this.currencies = this.retourClientWS.data ; 
        }
        else
          console.log("Failed") ;
      }) ;    
  }


  saveOperation(){

    this.voletTampon =this.volet ;
    this.volet = "loader" ;

    this.configServer.initierVersement(this.samacaabiyoonwii, this.montant, this.userinfos.iduser, this.choosenNom, this.choosenCode, this.choosenId).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Operation Saved !") ;
        this.successOp = true ;
        this.montant = undefined ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }


  annuler(){
  	this.dialogRef.close() ;
  }

  reinitPassWord(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

    this.configServer.updateMotDePasseUser(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Update Password !") ;
        this.successOp = true ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }


  desactiverCompte(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

      this.configServer.desactiverCompte(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi) ;
        if(statutreq==1){
          console.log("Done Update Password !") ;
          this.successOp = true ;
        }
        else{
          console.log("Failed") ;
          this.failureOp = true ;
        }
      }) ;    
  }


  getLastActivity(){

      this.voletTampon =this.volet ;
      /*this.volet = "loader" ;*/

      this.configServer.getLastActivity(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi.data) ;
        if(statutreq==1){
          console.log("Done Update Password !") ;
          this.activite  = retourbi.data.session_state;
          this.dateLastConnexion = retourbi.data.session_start;
          this.actifDepuis = retourbi.data.session_start;
          this.inactifDepuis = retourbi.data.session_end;
        }
        else{
          console.log("Failed") ;
        }
      }) ;    
  }



}
