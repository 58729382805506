import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';

import * as sha1 from 'js-sha1';


const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

interface UserInput {
    iduser: number;
    prenom: string;
} ;


declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
/*    { path: '/homecpnt/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },*/
    { path: '/homecpnt/partners', title: 'Réseau',  icon: 'toc', class: '' },
    { path: '/homecpnt/finances', title: 'Finances',  icon:'library_books', class: '' },
/*    { path: '/homecpnt/documents', title: 'G.E.D / Archives',  icon: 'money', class: '' }*/
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {

    var ROUTES2 = [
      { path: '/homecpnt/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
      { path: '/homecpnt/compta', title: 'Reporting',  icon:'library_books', class: '' },
    ];

    var aL = sessionStorage.getItem("accessl") ;

    if(aL=="2"){
      this.menuItems = ROUTES2.filter(menuItem => menuItem);
    }else{
      this.menuItems = ROUTES.filter(menuItem => menuItem);      
    }

  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  }



  guenneKo(){
    this.disconnect() ;
  }


  disconnect(){    

    let samacaabiyoonwiiThere = sessionStorage.getItem("samacaabiyoonwii") ;

    console.log(samacaabiyoonwiiThere);

    let logdata = {samacaabiyoonwii: samacaabiyoonwiiThere } ;  

    this.remoteDisconnect('https://shift.smkfinances.com/change/disconnect.php', logdata ).subscribe(
        retourPost => {
          console.log(retourPost) ;
          sessionStorage.removeItem("accessl") ;
          sessionStorage.removeItem("samacaabiyoonwii") ;
          sessionStorage.clear();
          this.router.navigate(['/']) ;
    } ) ;              
  }

  remoteDisconnect(url: string, user: any) {
    return this.http.post(url, user, httpOptions);
  }




}
