import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';



import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { AgmCoreModule } from '@agm/core';


import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {MatExpansionModule} from '@angular/material/expansion';
import {CdkAccordionModule} from '@angular/cdk/accordion';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';



import { MatTimepickerModule } from 'mat-timepicker';


import {DragDropModule} from '@angular/cdk/drag-drop';


import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthentificationComponent } from './authentification/authentification.component';
import { DeliverymanagerComponent } from './deliverymanager/deliverymanager.component';
import { CommandesComponent } from './commandes/commandes.component';
import { LivraisonComponent } from './livraison/livraison.component';
import { PartenairesComponent } from './partenaires/partenaires.component';
import { DetailcommandeComponent } from './detailcommande/detailcommande.component';

import { Deliverymodale } from './livraison/livraison.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmsouscriptionComponent } from './confirmsouscription/confirmsouscription.component';
import { UsrcreationComponent } from './usrcreation/usrcreation.component';
import { UserplanningComponent } from './userplanning/userplanning.component';
import { QraccueilComponent } from './qraccueil/qraccueil.component';
import { BulletinsalaireComponent } from './bulletinsalaire/bulletinsalaire.component';
import { FileuploaderComponent } from './fileuploader/fileuploader.component';
import { ExportationComponent } from './exportation/exportation.component';
import { UseroptionsComponent } from './useroptions/useroptions.component';
import { PointoptionsComponent } from './pointoptions/pointoptions.component';
import { FinancesComponent } from './finances/finances.component';
import { MakebankComponent } from './makebank/makebank.component';
import { MakeserviceComponent } from './makeservice/makeservice.component';
import { DetailsComponent } from './details/details.component';
import { DetailserviceComponent } from './detailservice/detailservice.component';
import { DetailagenceComponent } from './detailagence/detailagence.component';
import { PartneroptionsComponent } from './partneroptions/partneroptions.component';
import { MakeNewRateComponent } from './make-new-rate/make-new-rate.component';
import { MakenewrateComponent } from './makenewrate/makenewrate.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,
    MatRadioModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTimepickerModule,
    HttpClientModule,
    ComponentsModule,
    PdfViewerModule,
    MatExpansionModule,
    CdkAccordionModule,

    MatTableModule,
    MatPaginatorModule,
    MatSortModule,


    DragDropModule,
    RouterModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthentificationComponent,
    DetailcommandeComponent,
    Deliverymodale,
    ConfirmationDialogComponent,
    ConfirmsouscriptionComponent,
    UsrcreationComponent,
    UserplanningComponent,
    QraccueilComponent,
    BulletinsalaireComponent,
    FileuploaderComponent,
    ExportationComponent,
    UseroptionsComponent,
    PointoptionsComponent,
    FinancesComponent,
    MakebankComponent,
    MakeserviceComponent,
    DetailsComponent,
    DetailserviceComponent,
    DetailagenceComponent,
    PartneroptionsComponent,
    MakeNewRateComponent,
    MakenewrateComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
